import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "./Select";

export default function BlogSection() {
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedWroteFor, setSelectedWroteFor] = useState("");
  const [categories, setCategories] = useState([]);
  const [wroteForOptions, setWroteForOptions] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        let apiUrl = "https://lucasbfs.pythonanywhere.com/api/posts/";

        if (selectedCategory) {
          apiUrl += `?category=${selectedCategory}`;
        }

        if (selectedWroteFor) {
          apiUrl += `${
            selectedCategory ? "&" : "?"
          }wroteFor=${selectedWroteFor}`;
        }

        const response = await axios.get(apiUrl);
        setPosts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPosts();
  }, [selectedCategory, selectedWroteFor]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://lucasbfs.pythonanywhere.com/api/categories/"
        );
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchWroteForOptions = async () => {
      try {
        const response = await axios.get(
          "https://lucasbfs.pythonanywhere.com/api/wroteFor/"
        );
        // Update state variable name to wroteForOptions
        setWroteForOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCategories();
    fetchWroteForOptions();
  }, []);

  const filteredPosts = posts.filter((post) => {
    const categoryMatch =
      selectedCategory === "" ||
      post.category.title
        .toLowerCase()
        .includes(selectedCategory.toLowerCase());
    const wroteForMatch =
      selectedWroteFor === "" ||
      (post.wroteFor &&
        post.wroteFor.title
          .toLowerCase()
          .includes(selectedWroteFor.toLowerCase()));
    return categoryMatch && wroteForMatch;
  });

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleWroteForChange = (e) => {
    setSelectedWroteFor(e.target.value);
  };

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Artículos Publicados
          </h2>
        </div>
        {/* Filter options */}
        <div className="mt-8 flex flex-wrap gap-4 justify-between">
          <Select
            label="Categoría: "
            value={selectedCategory}
            onChange={handleCategoryChange}
            mapping={categories}
            optionInit="Todas"
          />

          <Select
            label="Escrito para: "
            value={selectedWroteFor}
            onChange={handleWroteForChange}
            mapping={wroteForOptions}
            optionInit="Sin preferencia"
          />
        </div>

        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-3.5 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {filteredPosts.map((post) => (
            <article key={post.id} className="flex flex-col items-start">
              {" "}
              {/* I removed here justify-between in <article>*/}
              <div className="relative w-full">
                <img
                  src={post.imageUrl}
                  alt=""
                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div className="max-w-xl">
                <div className="mt-8 flex items-center gap-x-4 text-xs">
                  <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time>
                  <p
                    href={post.category.href}
                    className="relative z-10 rounded-full px-3 py-1.5 font-medium text-gray-600"
                  >
                    {post.category.title}
                  </p>
                  <div>{post.wroteFor ? post.wroteFor.title : ""}</div>
                </div>
                <div className="group relative">
                  <Link to={`/full-article/${post.id}`}>
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <span className="absolute inset-0" />
                      {post.title}
                    </h3>
                    <p className="mt-5 text-sm leading-6 text-gray-600">
                      {post.description}{" "}
                    </p>
                    <span className="absolute left-0 text-sm text-indigo-700">
                      Lee más...
                    </span>
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
