import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import FullArticles from "./pages/FullArticles";
import AboutMe from "./components/AboutMe";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Header />} />
        <Route
          exact
          path="/full-article/:uniqueID"
          element={<FullArticles />}
        />
        <Route path="/about-me" element={<AboutMe />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// {
//   /* <header className="App-header">
//         <h1 className="text-3xl font-bold underline">Hello World!</h1>
//         <img src={logo2} />
//         <Logo1 />
//       </header> */
// }
