function Select(props) {
  return (
    <div className="flex items-center gap-2">
      <label>{props.label}</label>
      <select
        value={props.value}
        onChange={props.onChange}
        className="rounded-lg bg-blue-100 py-2 pl-3 pr-10 text-blue-800 shadow-md focus:outline-none"
      >
        <option value="">{props.optionInit}</option>
        {props.mapping.map((category) => (
          <option key={category.id} value={category.title}>
            {category.title}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
