import { Fragment, useState } from "react";
import emailjs from "@emailjs/browser";

const Message = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const sendEmailFromEmailJs = () => {
    const emailData = {
      to_name: "Guillermo",
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_p5vphfa",
        "template_byqm47l",
        emailData,
        "tdUdEnZk5MGb9InBY"
      )
      .then((response) => {
        console.log("Email sent successfully:", response.text);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, name: e.target.value });
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, email: e.target.value });
  };

  const handleMessageChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, message: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmailFromEmailJs();
    setFormData({ ...formData, message: "" });
    setFormData({ ...formData, email: "" });
  };

  return (
    <Fragment>
      <form
        className="bg-white p-8 shadow-lg rounded-lg w-80 lg:w-96 h-72 flex flex-col justify-center"
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <input
            type="text"
            placeholder="Ingrese su nombre y apellido"
            className="w-full px-4 py-2 rounded-md bg-gray-100 text-gray-900 my-4"
            name="user_name"
            onChange={handleNameChange}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Ingrese su correo electrónico"
            className="w-full px-4 py-2 rounded-md bg-gray-100 text-gray-900 my-4"
            name="user_email"
            onChange={handleEmailChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="sr-only">
            Mensaje
          </label>
          <textarea
            className="w-full px-4 py-2 rounded-md bg-gray-100 text-gray-900"
            id="message"
            placeholder="Por favor, ingrese su mensaje"
            value={formData.message}
            onChange={handleMessageChange}
          />
        </div>
        <button
          className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md"
          type="submit"
          onClick={handleSubmit}
        >
          Send
        </button>
      </form>
    </Fragment>
  );
};

export default Message;
