import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import bgPhoto from "../assets/images/bckgrndPhoto2.jpeg";

export default function AboutMe() {
  return (
    <div>
      <div className="flex items-center justify-center min-h-screen bg-slate-950">
        <Link to="/" className="absolute top-4 left-4">
          <BiArrowBack size="30px" color="white" />
        </Link>
        <div className="relative isolate overflow-hidden bg-gray-900 py-8 sm:py-16 md:py-24 lg:py-32 w-full">
          <img
            src={bgPhoto}
            alt="boat"
            className="absolute inset-0 -z-10 h-full w-full object-cover brightness-50 blur-sm"
          />
          <div
            className="hidden md:absolute md:-top-10 md:right-1/2 md:-z-10 md:mr-10 md:block md:transform-gpu md:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-20 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl md:-top-52 md:ml-16 md:translate-x-0 md:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto w-11/12 md:w-3/4 lg:w-1/2">
            <h2 className="text-2xl sm:text-4xl md:text-6xl font-bold tracking-tight text-white backdrop-blur-sm">
              Guillermo Fronciani
            </h2>
            <p className="mt-4 sm:mt-6 md:mt-8 text-base sm:text-lg md:text-xl leading-6 sm:leading-8 text-white leading-10">
              ¡Bienvenidos! Soy Guillermo Maria Fronciani, un apasionado abogado
              con una sólida trayectoria en el campo del derecho corporativo.
              Además, tengo el privilegio de desempeñarme como vicepresidente de
              la compañía Seguridad Seguros. A lo largo de mi carrera, he
              trabajado arduamente para brindar asesoramiento legal de calidad y
              soluciones estratégicas a nuestros clientes. Este blog es mi
              espacio personal donde comparto conocimientos, experiencias y
              reflexiones sobre el mundo legal y el fascinante campo de los
              seguros. ¡Acompáñame en este viaje mientras exploramos juntos los
              entresijos legales y las últimas tendencias en el sector de
              seguros!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
