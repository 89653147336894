import { EnvelopeIcon } from "@heroicons/react/20/solid";
import headerBackgrnd from "../assets/images/background-image-gmf.jpeg";
import profilePic from "../assets/images/profile.JPG";
import BlogSection from "./BlogSection";
import { Fragment, useState } from "react";
import Message from "./Message";
import { Link } from "react-router-dom";

const profile = {
  name: "Guillermo Fronciani",
  avatar: profilePic,
  backgroundImage: headerBackgrnd,
};

export default function Header() {
  const [cardIsActive, setCardIsActive] = useState(false);

  const handlingCardActivation = (e) => {
    e.preventDefault();
    setCardIsActive(!cardIsActive);
  };

  return (
    <Fragment>
      <div>
        <div>
          <img
            className="h-32 w-full object-cover lg:h-48"
            src={profile.backgroundImage}
            alt=""
          />
        </div>
        <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
            <div className="flex">
              <img
                className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                src={profile.avatar}
                alt=""
              />
            </div>
            <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1 sm:hidden md:block">
                <h1 className="truncate text-2xl font-bold text-gray-900 ">
                  {profile.name}
                </h1>
              </div>

              <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                <div className="flex items-center pr-10 font-semibold	text-indigo-700">
                  <Link to="/about-me">Sobre mi</Link>
                </div>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={handlingCardActivation}
                >
                  <EnvelopeIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Mensaje</span>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden">
            <h1 className="truncate text-2xl font-bold text-gray-900">
              {profile.name}
            </h1>
          </div>
        </div>
      </div>

      {/* All articles are here */}
      <div>
        <BlogSection />
      </div>
      {cardIsActive && (
        <div
          className="fixed inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-50"
          onClick={handlingCardActivation}
        >
          {" "}
          <Message cardIsActive={cardIsActive} />
        </div>
      )}
    </Fragment>
  );
}
